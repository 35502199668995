import "./axios";

const fontsToLoad = [
    "12px 'Material Symbols Outlined'"
];

const fontLoadPromises = fontsToLoad.map(font => document.fonts.load(font));
Promise.all(fontLoadPromises).then(() => {
    document.body.classList.add("material-symbols-loaded");
});
